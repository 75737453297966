var moment = require('moment')//for get time
import Vue from 'vue'
import api from '@/apis/siteDeviceEvent'
import parser from '@/models/siteEvent'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  INIT_DEVICE_EVENTS,
  GET_DEVICE_EVENTS_REQUEST,
  GET_DEVICE_EVENTS_SUCCESS,
  GET_DEVICE_EVENTS_FAILURE,
  GET_DEVICE_TIMELAPSE_EVENTS_REQUEST,
  GET_DEVICE_TIMELAPSE_EVENTS_SUCCESS,
  GET_DEVICE_TIMELAPSE_EVENTS_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  // only events in the selected device will be stored
  deviceId: null,
  events: null,
  timelapseEvents: null,
  eventIds: null,
  timelapseEventIds: null,
  status: {
    getEvents: null,
    getTimelapseEvents : null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  deviceEvent: function (state, getters) {
    return function (eventId) {
      return getters.deviceEvents[eventId]
    }
  },
  deviceEvents: function (state) {
    return state.events || {}
  },
  deviceTimelapseEvents: function (state) {
    return state.timelapseEvents || {}
  },
  deviceTimelapseEvent: function (state, getters) {
    return function (eventId) {
      return getters.deviceTimelapseEvents[eventId]
    }
  },
  deviceEventIds: function (state) {
    return state.eventIds || []
  },
  deviceFirstEventId: function (state, getters) {
    if (getters.deviceEventIds.length < 1) return ''
    return getters.deviceEventIds[0]
  },
  deviceLastEventId: function (state, getters) {
    if (getters.deviceEventIds.length < 1) return ''
    return getters.deviceEventIds[getters.deviceEventIds.length - 1]
  },
  timelapseEventIds: function (state) {
    return state.timelapseEventIds || []
  },
  deviceFirstTimelapseEventId: function (state, getters) {
    if (getters.timelapseEventIds.length < 1) return ''
    return getters.timelapseEventIds[0]
  },
  deviceLastTimelapseEventId: function (state, getters) {
    if (getters.timelapseEventIds.length < 1) return ''
    return getters.timelapseEventIds[getters.timelapseEventIds.length - 1]
  },
  statusGetDeviceEvents: function (state) {
    return state.status.getEvents
  },
}

// actions
const actions = {
  getDeviceEvents: function ({commit, getters}, {siteId, deviceId, stime, etime, eventTypes, eventStatus, toGetNewEvents, siteTimezone}) {
    commit(GET_DEVICE_EVENTS_REQUEST, {siteId, deviceId, stime, etime})
    // SET - targetEventId
    let targetEventId = ''
    if (toGetNewEvents) targetEventId = getters.deviceLastEventId
    else targetEventId = getters.deviceFirstEventId
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteDeviceEvents({siteId, deviceId, stime, etime, eventTypes, eventStatus, targetEventId, toGetNewEvents, siteTimezone}).then(
        res => {
          let data = res.body
          commit(GET_DEVICE_EVENTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_DEVICE_EVENTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getDeviceTimelapseEvents: function ({commit, getters}, {siteId, deviceId, stime, etime, eventTypes, eventStatus, toGetNewEvents, siteTimezone}) {
    commit(GET_DEVICE_TIMELAPSE_EVENTS_REQUEST, {siteId, deviceId, stime, etime})
    // SET - targetEventId
    let targetEventId = ''
    if (toGetNewEvents) targetEventId = getters.deviceLastTimelapseEventId
    else targetEventId = getters.deviceFirstTimelapseEventId
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteDeviceEvents({siteId, deviceId, stime, etime, eventTypes, eventStatus, targetEventId, toGetNewEvents, siteTimezone}).then(
        res => {
          let data = res.body
          commit(GET_DEVICE_TIMELAPSE_EVENTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_DEVICE_TIMELAPSE_EVENTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_DEVICE_EVENTS]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_DEVICE_EVENTS_REQUEST]: function (state, {siteId, deviceId}) {
    // RESET_STATE - device or dateKey changed
    if ((state.siteId && state.siteId !== siteId) || (state.deviceId && state.deviceId !== deviceId)) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.deviceId = deviceId
    state.status.getEvents = "requested"
  },
  [GET_DEVICE_EVENTS_SUCCESS]: function (state, {data}) {
    // INIT
    let events = {}
    let eventIds = []

    // PARSE
    data.map(eventData => {
      const eventInfo = parser.parseEventData(eventData)
      const eventId = eventInfo.eventId

      if (eventId) {
        events[eventId] = eventInfo
        eventIds.push(eventId)
      }
    })

    // MERGE - eventIds with existing eventIds
    if (state.eventIds instanceof Array) {
      state.eventIds.map(eventId => {
        if (eventIds.indexOf(eventId) < 0) eventIds.push(eventId)
      })
    }

    // SORT - without affecting `state.eventIds`
    eventIds.sort((a,b) => a-b)

    // SET - state.eventIds
    Vue.set(state, 'eventIds', eventIds)

    // COMBINE existing events with newly retrieved events
    // CASE 1,2,3,4 - To prevent duplicated event retrieval
    if (!(state.events instanceof Object)) state.events = {}
    Object.assign(state.events, events)
    state.status.getEvents = "successful"
  },
  [GET_DEVICE_EVENTS_FAILURE]: function (state) {
    state.status.getEvents = "failed"
  },
  [GET_DEVICE_TIMELAPSE_EVENTS_REQUEST]: function (state, {siteId, deviceId}) {
    // RESET_STATE - device or dateKey changed
    if ((state.siteId && state.siteId !== siteId) || (state.deviceId && state.deviceId !== deviceId)) {
      for (let f in state) {
        Vue.set(state, f, initialState[f])
      }
    }
    state.siteId = siteId
    state.deviceId = deviceId
    state.status.getTimelapseEvents = "requested"
  },
  [GET_DEVICE_TIMELAPSE_EVENTS_SUCCESS]: function (state, {data}) {
    // INIT
    let timelapseEvents = {}
    let timelapseEventIds = []

    // PARSE
    data.map(eventData => {
      const eventInfo = parser.parseEventData(eventData)
      const eventId = eventInfo.eventId

      if (eventId) {
        timelapseEvents[eventId] = eventInfo
        timelapseEventIds.push(eventId)
      }
    })

    // MERGE - timelapseEventIds with existing timelapseEventIds
    if (state.timelapseEventIds instanceof Array) {
      state.timelapseEventIds.map(eventId => {
        if (timelapseEventIds.indexOf(eventId) < 0) timelapseEventIds.push(eventId)
      })
    }

    // SORT - without affecting `state.timelapseEventIds`
    timelapseEventIds.sort((a,b) => a-b)

    // SET - state.timelapseEventIds
    Vue.set(state, 'timelapseEventIds', timelapseEventIds)

    // COMBINE existing events with newly retrieved events
    // CASE 1,2,3,4 - To prevent duplicated event retrieval
    if (!(state.timelapseEvents instanceof Object)) state.timelapseEvents = {}
    Object.assign(state.timelapseEvents, timelapseEvents)
    state.status.getTimelapseEvents = "successful"
  },
  [GET_DEVICE_TIMELAPSE_EVENTS_FAILURE]: function (state) {
    state.status.getTimelapseEvents = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}