import { Device } from '@capacitor/device';
import { Deploy } from 'cordova-plugin-ionic'

const DeployPlugin = {
  install (Vue) {
    let deploy = {
      channels: [
        'DEV',
        'Production-s1',
        'Production',
        'Production-capacitor-5',
      ],
      isProduction: false,
      currentConfig: {
        'binaryVersionName': '',    // "binaryVersionName": "1.0"
        'binaryVersion': '',        // "binaryVersion": "1.0"
        'maxVersions': 0,           // "maxVersions": 2 <=== The number of previous updates to be cached on the device
        'channel': '',              // "channel": "dev-s1"
        'updateMethod': '',         // "updateMethod": "none" <=== ["none" | "auto" | "background"]
        'disabled': false,          // "disabled": false
        'appId': '',                // "appId": "b7e389a4"
        'host': '',                 // "host": "https://api.ionicjs.com"
        'binaryVersionCode': '',    // "binaryVersionCode": "1"
        'minBackgroundDuration': 0, // "minBackgroundDuration": 30 <=== The number of seconds the app should be in the background for before the plugin considers it closed and checks for an updated on resume of the app.
      },
      updateEnvironment: async function () {
        // UPDATE - enviroment variables
        this.isProduction = false
        // this.isProduction = true // *****for testing*****
        // REFRESH - current config
        await this.refreshCurrentConfig()
        // UPDATE - isProduction
        if (this.currentConfig.channel.toLowerCase().indexOf('prod') > -1) this.isProduction = true
        Vue.env.updateEnvironment(this.isProduction)
      },
      refreshCurrentConfig: async function () {
        // WHEN - running on mobile devices as an 'app'
        if ((await Device.getInfo()).platform != 'web') {
          Object.assign(this.currentConfig, await Deploy.getConfiguration())
        } else {
          this.currentConfig.channel = this.getWebEnvConfig()
        }
      },
      getWebEnvConfig: function () {
        const host = location.hostname
        switch (host) {
          case 'localhost': return 'dev'
          case 'chektdev.com': return 'dev'
          case 'app-s1.chekt.com': return 'prod'
          case 'app.chekt.com': return 'prod'
          default: return 'dev'
        }
      },
      changeChannel: async function (channel) {
        // WHEN - running on mobile devices as an 'app'
        if ((await Device.getInfo()).platform != 'web') {
          await Deploy.configure({channel})
        }
        // UPDATE - enviroment variables
        await this.updateEnvironment()
      },
      performAutomaticUpdate: async function () {
        // WHEN - running on mobile devices as an 'app'
        if ((await Device.getInfo()).platform == 'web') return
        try {
          const currentVersion = await Deploy.getCurrentVersion()
          const resp = await Deploy.sync({updateMethod: 'auto'}, percentDone => {
            console.log(`Update is ${percentDone}% done!`)
          })
          if (!currentVersion || currentVersion.versionId !== resp.versionId){
            // We found an update, and are in process of redirecting you since you put auto!
            console.log(`We found an update, and are in process of redirecting you since you put auto!`)
          } else {
            // No update available
            console.log(`No update available`)
          }
        } catch (err) {
          // We encountered an error.
          console.log(`We encountered an error: ${err}`)
        }
        // UPDATE - enviroment variables
        await this.updateEnvironment()
      },
      performManualUpdate: async function () {
        // WHEN - running on mobile devices as an 'app'
        if ((await Device.getInfo()).platform == 'web') return

        // CHECK - update
        const update = await Deploy.checkForUpdate()
        if (!update.available) return
        // DOWNLOAD
        await Deploy.downloadUpdate((progress) => {
          console.log(`Download is ${progress}% done!`)
        })
        // EXTRACT
        await Deploy.extractUpdate((progress) => {
          console.log(`Extract is ${progress}% done!`)
        })
        await Deploy.reloadApp()
        // UPDATE - enviroment variables
        await this.updateEnvironment()
      },
      reloadApp: async function () {
        // WHEN - running on mobile devices as an 'app'
        if ((await Device.getInfo()).platform == 'web') return
        await Deploy.reloadApp()
        // UPDATE - enviroment variables
        await this.updateEnvironment()
      },
    }

    // START - refreshing current config
    let startRefreshingCurrentConfig = async function () {
      await deploy.updateEnvironment()
    }
    startRefreshingCurrentConfig()

    Vue.prototype.$deploy = deploy
    Vue.deploy = deploy
  }
}

export default DeployPlugin
