import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getWebrtcIceServers: function ({siteId, provider}) {
    let query = ''
    if (provider) query = `?provider=${provider}`
    let apiDetailPath = this.apiPrefix + `/${siteId}/ice_servers${query}`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  createWebrtcPeer: function ({siteId, bridgeId, iceServers, offer, myStream}) {
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/peers`
    let data = {
      headers: {
        method: 'post',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      },
      body: {
        ice_servers: iceServers,
        offer: offer,
      }
    }

    if (myStream) data.body.my_stream = myStream

    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}