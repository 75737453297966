import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getOnvifRecordingConfigFromProxy: function ({siteId, bridgeId, channel}) {
    if (channel < 10) channel = '0' + channel 

    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/camera/ch${channel}/onvif/recordings?i_uri=yes`
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
  getPlaybackVideoFromProxy: function ({siteId, bridgeId, rtspUrl, startTime, endTime}) {
    let timeout = 60
    let apiDetailPath = this.apiPrefix + `/${siteId}/devices/${bridgeId}/proxy?timeout=${timeout}`
    let bridgeApiPath = `ckbapiv2/streamer/download?url=${rtspUrl}&stime=${startTime}&etime=${endTime}` // e.g 2024-09-10T12:52:22Z
    let data = {
      headers: {
        method: 'get',
        path: bridgeApiPath,
        mqttResponseTopic: `devices/${siteId}/${bridgeId}/0/ckbapiv2/res/` + Math.floor(Math.random() * 1000000000000)
      }
    }
    return Vue.http.put(Vue.env.apiPath + apiDetailPath, data, Vue.auth.getBearerHeader())
  },
}