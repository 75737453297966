const ActlogPlugin = {
  install (Vue, {store}) {

    let actlog = {

      descIds: {
        armed:                      [ 30001,30003,31065,31188,31189,31066,31067,31068 ],
        disarmed:                   [ 30002,30004,31062,31063,31064 ],
        scheduleArmed:              [ 32036 ],
        scheduleDisarmed:           [ 32035 ],
        alarmVideo:                 [ 30011,30305,31223,31085,32033,32041 ],
        disarmedVideo:              [ 32054,32076 ],
        automationVideo:            [ 32037 ],
        playbackVideo:              [ 30022,31225 ],
        timelapseVideo:             [ 30021,31226 ],
        relayEnergized:             [ 30032,31053,31054 ],
        relayDeenergized:           [ 30033,31055,31056 ],
        bypassed:                   [ 31002,31013,31024,31033,31105,31209,32002,32012,32022 ],
        swingerBypassed:            [ 31003,31014,31025,31034,31106,31213,32003,32013,32023 ],
        endOfBypass:                [ 31004,31015,31026,31035,31107,31210,32004,32014,32024 ],
        audioByTts:                 [ 31175 ],
        audioByFile:                [ 31178 ],
        deviceConnectionOnline:     [ 30101 ],
        deviceConnectionOffline:    [ 30102 ],
      },

      deviceDescIds: {},

      getDescType: function (descId) {
        // INIT - Description type
        var descType = ''
        
        // CHECK - Description type
        if (this.descIds.armed.includes(descId))                          descType = 'armed'
        else if (this.descIds.disarmed.includes(descId))                  descType = 'disarmed'
        else if (this.descIds.scheduleArmed.includes(descId))             descType = 'scheduleArmed'
        else if (this.descIds.scheduleDisarmed.includes(descId))          descType = 'scheduleDisarmed'
        else if (this.descIds.alarmVideo.includes(descId))                descType = 'alarmVideo'
        else if (this.descIds.disarmedVideo.includes(descId))             descType = 'disarmedVideo'
        else if (this.descIds.playbackVideo.includes(descId))             descType = 'playbackVideo'
        else if (this.descIds.timelapseVideo.includes(descId))            descType = 'timelapseVideo'
        else if (this.descIds.automationVideo.includes(descId))           descType = 'automationVideo'
        else if (this.descIds.relayEnergized.includes(descId))            descType = 'relayEnergized'
        else if (this.descIds.relayDeenergized.includes(descId))          descType = 'relayDeenergized'
        else if (this.descIds.bypassed.includes(descId))                  descType = 'bypassed'
        else if (this.descIds.swingerBypassed.includes(descId))           descType = 'swingerBypassed'
        else if (this.descIds.endOfBypass.includes(descId))               descType = 'endOfBypass'
        else if (this.descIds.audioByTts.includes(descId))                descType = 'audioByTts'
        else if (this.descIds.audioByFile.includes(descId))               descType = 'audioByFile'
        else if (this.descIds.deviceConnectionOnline.includes(descId))    descType = 'online'
        else if (this.descIds.deviceConnectionOffline.includes(descId))   descType = 'offline'
        else descType = 'unknown'
        
        // RETURN - Description type
        return descType
      },
      
      // for manage ids
      filterLogIds: [],
      deviceFilterLogIds: [],
      
      // *** IMPORTANT! do not change or remove id just add!!
      // *** IMPORTANT! do not change or remove id just add!!
      // *** IMPORTANT! do not change or remove id just add!!
      // (because is using local storage)

      // even if you change checked status, will not change the status. it is only for init default set.
      filterItems: [
        {id:100, checked:true,  show:true, name:'arming events'},
        {id:200, checked:true,  show:true, name:'alarm video'},
        {id:201, checked:false, show:true, name:'disarmed video'},
        {id:210, checked:false, show:true, name:'timelapse video'},
        {id:220, checked:true,  show:true, name:'automation video'},
        {id:300, checked:true,  show:true, name:'relay events'},
        {id:400, checked:true,  show:true, name:'audio events'},
        {id:500, checked:true,  show:true, name:'zone bypass'},
        {id:600, checked:false, show:true, name:'network status'},
      ],

      filterIdsToString: function (ids) {
        let idsString = ''
        if (!(ids instanceof Array)) return
        // Number to string 
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          if (i === ids.length-1) idsString += id
          else idsString += id + '||'
        }
        return idsString
      },

      filterBySiteConfig: async function () {
        let siteId = store.getters.selectedSiteId
        let site = store.getters.sites[siteId]

        if (!site) return
        if (!site.statusInfo) return
        // get filter show hid info from site info
        let showArmingLog = site.statusInfo.showArmingNotification
        let showEventLog = site.statusInfo.showEventNotification
        let showBypassLog = site.statusInfo.showBypassNotification
        let showNetworkLog = site.statusInfo.showDeviceConnectionNotification
        let showDisarmedEventLog = site.statusInfo?.showDisarmedActivityNotification

        let filterItemsFromStorage = await this.getFilterItemsInStorage()
        if (!filterItemsFromStorage) filterItemsFromStorage = this.filterItems

        if (!(filterItemsFromStorage instanceof Array)) return
        for (let i = 0; i < filterItemsFromStorage.length; i++) {
          const item = filterItemsFromStorage[i];
          
          // Arming
          if (item.id === 100) {
            if (showArmingLog) item.show = true
            else item.show = false
          }
          // Event
          else if (item.id === 200) {
            if (showEventLog) item.show = true
            else item.show = false
          }
          // Bypass
          else if (item.id === 500) {
            if (showBypassLog) item.show = true
            else item.show = false
          }
          // Network
          else if (item.id === 600) {
            if (showNetworkLog) item.show = true
            else item.show = false
          }
          // Disarmed
          else if (item.id === 201) {
            if (showDisarmedEventLog) item.show = true
            else item.show = false
          }
          // others...
          else item.show = true
        }
        // set in storage
        await this.setFilterItemsInStorage(filterItemsFromStorage)
      },

      checkDefaultItemsIsChanged: async function () {
        // get filter info from local storage
        let filterItemsFromStorage = await this.getFilterItemsInStorage()

        ///////////////////////////////
        // make data for compare
        let filterItemsInStorage = filterItemsFromStorage
        let filterItemsInStore = this.filterItems

        if (!(filterItemsInStorage instanceof Array)) return

        ////////////////////////////////////////////////////
        // 1. Check - default(filterItems) name is changed?
        for (let i = 0; i < filterItemsInStore.length; i++) {
          const filterItemInPlugin = filterItemsInStore[i];
          for (let ii = 0; ii < filterItemsInStorage.length; ii++) {
            const storageFilterItem = filterItemsInStorage[ii];
            if(filterItemInPlugin.id === storageFilterItem.id) {
              // name add to storage
              storageFilterItem.name = filterItemInPlugin.name 
            }
          }
        }
        await this.setFilterItemsInStorage(filterItemsInStorage)

        ///////////////////////////////////////////////////
        // 2. Check - default(filterItems) added somthing?
        let addedArr = Vue.tool.parseToJSON(Vue.tool.parseToStringify(filterItemsInStorage))
        for (let i = 0; i < filterItemsInStore.length; i++) {
          const filterItemInPlugin = filterItemsInStore[i];
          let isDuplicate = 0
          for (let ii = 0; ii < filterItemsInStorage.length; ii++) {
            const storageFilterItem = filterItemsInStorage[ii];

            if(filterItemInPlugin.id === storageFilterItem.id) {
              isDuplicate++
            }
          }
          // e.g) default filter = 5, storage filter = 4
          // add filter in local storage
          if (isDuplicate < 1) {
            addedArr.splice(i, 0, filterItemInPlugin);
            await this.setFilterItemsInStorage(addedArr)
          }
        }

        ////////////////////////////////////////////////////
        // 3. Check - default(filterItems) removed somthing?
        let remainArr = []
        let hasRemain = false
        for (let i = 0; i < filterItemsInStorage.length; i++) {
          const filterItemInStorage = filterItemsInStorage[i];
          let isDuplicate = 0
          for (let ii = 0; ii < filterItemsInStore.length; ii++) {
            const filterItemInPlugin = filterItemsInStore[ii];

            if(filterItemInPlugin.id === filterItemInStorage.id) {
              filterItemInPlugin.checked = filterItemInStorage.checked
              isDuplicate++
              remainArr.push(filterItemInPlugin)
            }
          }
          // e.g) default filter = 4, storage filter = 5
          // remove filter in local storage
          if (isDuplicate < 1) {
            hasRemain = true
          }
        }
        if (hasRemain) await this.setFilterItemsInStorage(remainArr)

        //////////////////////////////////
        // ACTION - add data from storage
        let result = await this.getFilterItemsInStorage()

        return result
      },
      
      getFilterIds: function (selectedId) {
        // Init
        let filterIds = []
        let ids = this.descIds

        // CHECK - filter ids
        if      (selectedId === 100) filterIds = filterIds.concat(ids.armed, ids.disarmed, ids.scheduleArmed, ids.scheduleDisarmed)
        else if (selectedId === 200) filterIds = filterIds.concat(ids.alarmVideo, ids.playbackVideo)
        else if (selectedId === 201) filterIds = filterIds.concat(ids.disarmedVideo)
        else if (selectedId === 210) filterIds = filterIds.concat(ids.timelapseVideo)
        else if (selectedId === 220) filterIds = filterIds.concat(ids.automationVideo)
        else if (selectedId === 300) filterIds = filterIds.concat(ids.relayEnergized, ids.relayDeenergized)
        else if (selectedId === 400) filterIds = filterIds.concat(ids.audioByTts, ids.audioByFile)
        else if (selectedId === 500) filterIds = filterIds.concat(ids.bypassed, ids.swingerBypassed, ids.endOfBypass)
        else if (selectedId === 600) filterIds = filterIds.concat(ids.deviceConnectionOnline, ids.deviceConnectionOffline)
        
        // return filter ids
        return filterIds
      },
      getFilterItemsInStorage: async function () {
        // get filter items from local storage
        let filterItemsFromStorage = await Vue.tool.storageGetItem('activity_filter_items')
        let filterItemsFromStorageToJson = Vue.tool.parseToJSON(filterItemsFromStorage)

        // return result
        return filterItemsFromStorageToJson
      },
      setFilterItemsInStorage: async function (items) {
        if (!(items instanceof Object)) return
        let itemsToString = Vue.tool.parseToStringify(items)
        // set filter items to local storage
        await Vue.tool.storageSetItem('activity_filter_items', itemsToString)
      },
    }

    // `deviceFilterLogIds`를 초기화
    actlog.deviceFilterLogIds = [
      ...actlog.descIds.alarmVideo,
      ...actlog.descIds.disarmedVideo,
    ];

    actlog.deviceDescIds = {
      alarmVideo: actlog.descIds.alarmVideo,
      disarmedVideo: actlog.descIds.disarmedVideo,
    };

    Vue.prototype.$actlog = actlog
    Vue.actlog = actlog
  }
}

export default ActlogPlugin

// ***** ACTIVITY LOG DESCRIPTION CODE *****


// FW 2.X ********************************

// 30001 = Arming - Voltage Change - armed
// 30002 = Arming - Voltage Change - disarmed',
// 30003 = Arming - Programing Change - armed',
// 30004 = Arming - Programing Change - disarmed',

// 30011 = Video Event (DI trigger)
// 30305 = Video Event (OE trigger)

// 30021 = Timelapse

// 30022 = Playback

// 30032 = Relay - energized
// 30033 = Relay - deenergized

// 30201 = Video Event (alarm & restral) - 이 코드를 넣으면 alarm/restoral 알람 2개 다 옴. 그래서 현재 쓰지않음.


// FW 3.X ********************************

// 31065 = armed stay
// 31188 = armed stay by voltage
// 31189 = armed stay followed by main bridge
// 31066 = armed away
// 31067 = armed away by voltage
// 31068 = armed away followed by main bridge

// 31062 = disarmed
// 31063 = disarmed by voltage
// 31064 = disarmed followed by main bridge

// 32036 = schedule armed away
// 32035 = schedule disarmed

// 31053 = relay energized
// 31054 = relay energized with timer
// 31055 = relay deenergized
// 31056 = relay deenergized with timer

// 31223 = Video Event (DI & OE trigger)
// 32033 = Video Event (LE & LR local api trigger) - 31223 이벤트가 같이온다. 현재로선 막을 수 없다. 일단 event id 중복을 필터하고 있다.
// 32037 = Video Event (automation video event & automation playback event)
// 32041 = Video Event (AI - result true)
// 32054 = Video Event (disarmed video)
// 32076 = Video Event (disarmed video AI - result true)

// 31225 = Playback

// 31226 = Timelapse

// 31175 = audio playing tts
// 31178 = audio playing file

// 31002 = bypassed sensor general
// 31013 = bypassed sensor door window
// 31024 = bypassed sensor motion
// 31033 = bypassed sensor photobeam
// 31105 = bypassed camera recording
// 31209 = bypassed onvif general
// 32002 = bypassed optex motion
// 32012 = bypassed optex tamper
// 32022 = bypassed optex scenechange

// 31003 = swinger bypassed sensor general
// 31014 = swinger bypassed sensor door window
// 31025 = swinger bypassed sensor motion
// 31034 = swinger bypassed sensor photobeam
// 31106 = swinger bypassed camera recording
// 31213 = swinger bypassed onvif general
// 32003 = swinger bypassed optex motion
// 32013 = swinger bypassed optex tamper
// 32023 = swinger bypassed optex scenechange

// 31004 = end of bypass sensor general
// 31015 = end of bypass sensor door window
// 31026 = end of bypass sensor motion
// 31035 = end of bypass sensor photobeam
// 31107 = end of bypass camera recording
// 31210 = end of bypass onvif general
// 32004 = end of bypass optex motion
// 32014 = end of bypass optex tamper
// 32024 = end of bypass optex scenechange


// ⬇︎특정펌웨어(v3.0.0.18 외 ??) 버전에서만 사용되는 타입번호. snapshot/video 이벤트 둘다 오기때문에 같은 이밴트가 2개옴. 그래서 같은이벤트ID가 오는경우 하나만 쓰는 코드를 추가필요. 
// 31085 = Event (snapshot + video)