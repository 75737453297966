import Vue from 'vue'
import api from '@/apis/siteProxyRecording'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE,
  GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_REQUEST,
  GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_SUCCESS,
  GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_FAILURE,

  SET_RECORDING_TIME_INTERVAL,
  ON_CHANGE_RECORDING_TIME_INTERVAL_SLIDER,
  SET_ONVIF_PLAYBACK_INFO,
  SET_PLAYBACK_JUMP_TO,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && data.data instanceof Object && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  camerasOnvifRecordingConfig: {},
  onvifPlaybackInfo: {},
  playbckRecordingIntervalRange: 60,
  onChangePlaybckRecordingIntervalSlider: 60,
  playbackJumpTo: {},
  cameraPlaybackDownloadData: null,
  status: {
    camerasOnvifRecordingConfig: null,
    cameraPlaybackDownload: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  camerasOnvifRecordingConfig: function (state) {
    return state.camerasOnvifRecordingConfig
  },
  cameraOnvifRecordingConfig: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifRecordingConfig[deviceId]
    }
  },
  playbckRecordingIntervalRange: function (state) {
    return state.playbckRecordingIntervalRange
  },
  onChangePlaybckRecordingIntervalSlider: function (state) {
    return state.onChangePlaybckRecordingIntervalSlider
  },
  onvifPlaybackInfo: function (state) {
    return state.onvifPlaybackInfo
  },
  playbackJumpTo: function (state) {
    return state.playbackJumpTo
  },
  cameraPlaybackDownloadData: function (state) {
    return state.cameraPlaybackDownloadData
  },
}

// actions
const actions = {
  getSiteOnvifRecordingsConfigFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId}) {
    commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifRecordingConfigFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS, {data, deviceId})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getPlaybackVideoFromProxy: function ({commit}, {siteId, bridgeId, rtspUrl, startTime, endTime}) {
    commit(GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getPlaybackVideoFromProxy({siteId, bridgeId, rtspUrl, startTime, endTime}).then(
        res => {
          let data = res.body
          
          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_FAILURE)
          else commit(GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST]: function (state) {
    state.status.camerasOnvifRecordingConfig = 'requested'
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!deviceId) return
    if (!data?.data) return
    
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifRecordingConfig[deviceId]) {
      Vue.set(state.camerasOnvifRecordingConfig, deviceId, {});
    }

    // stop_time이 없는 경우에 대한 처리 (axis camera중 그런 경우를 발견 함)
    let recordings = data?.data?.recordings || [];
    recordings.forEach(recording => {
      if (recording?.is_recording && !recording?.stop_time) {
        const now = new Date();
        const oneMinuteAgo = new Date(now.getTime() - 60 * 1000);
    
        if (recording.start_time) {
          const startTime = new Date(recording.start_time);
    
          // start_time이 Now - 1분 보다 미래인 경우
          if (startTime > oneMinuteAgo) {
            recording.stop_time = new Date(now.getTime() - (now.getTime() - startTime.getTime())).toISOString();
          } else {
            recording.stop_time = oneMinuteAgo.toISOString();
          }
        } else {
          recording.stop_time = oneMinuteAgo.toISOString();
        }
      }
    });

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifRecordingConfig, deviceId, data?.data);

    state.status.camerasOnvifRecordingConfig = 'successful'
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE]: function (state) {
    state.status.camerasOnvifRecordingConfig = 'failed'
  },
  [SET_RECORDING_TIME_INTERVAL]: function (state, interval) {
    // 1 ~ 720 사이로 제한
    if (interval < 1) interval = 1;
    else if (interval > 720) interval = 720;
    state.playbckRecordingIntervalRange = interval;
  },
  [SET_ONVIF_PLAYBACK_INFO]: function (state, data) {
    state.onvifPlaybackInfo = {...data}
  },
  [SET_PLAYBACK_JUMP_TO]: function (state, data) {
    state.playbackJumpTo = {...data}
  },
  [ON_CHANGE_RECORDING_TIME_INTERVAL_SLIDER]: function (state, interval) {
    state.onChangePlaybckRecordingIntervalSlider = interval;
  },
  [GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_REQUEST]: function (state) {
    state.status.cameraPlaybackDownload = 'requested'
    // 요청 전 초기화
    state.cameraPlaybackDownloadData = null
  },
  [GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_SUCCESS]: function (state, {data}) {
    state.status.cameraPlaybackDownload = 'successful'
    state.cameraPlaybackDownloadData = data?.data
  },
  [GET_SITE_PLAYBACK_VIDEO_FROM_PROXY_FAILURE]: function (state) {
    state.status.cameraPlaybackDownload = 'failed'
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}