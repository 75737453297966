import Vue from 'vue'
import api from '@/apis/siteWebrtc'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  CREATE_WEBRTC_ICE_SERVERS_REQUEST,
  CREATE_WEBRTC_ICE_SERVERS_SUCCESS,
  CREATE_WEBRTC_ICE_SERVERS_FAILURE,
  UPDATE_WEBRTC_AUDIO_STATE,
  UPDATE_WEBRTC_AUDIO_SENDER_STATE,
  STORE_WEBRTC_AUDIO_CONTEXT,
  STORE_WEBRTC_AUDIO_SENDER_CONTEXT,
  STORE_WEBRTC_INFO_BRIDGES,
  SET_WEBRTC_VIDEO_LOG,
  REMOVE_WEBRTC_VIDEO_LOGS,

  SET_WEBRTC_VIDEO_DETAILS,
  SET_WEBRTC_VIDEO_DETAILS_TO_HTML,
  SET_CURRENT_PAGE_DEVICE_ID,
} from '@/store/mutation-types'

let initialState = {
  webrtcBridges: [],
  webrtcAudioState: null,
  webrtcAudioContext: null,
  webrtcAudioSenderState: null,
  webrtcAudioSenderContext: null,
  webrtcVideoLog: [],
  showWebrtcVideoDetails: false,
  status: {
    getWebrtcIceServers: null,
  },
  webrtcVideoDetailsHtml: ``,
  currentPageDeviceId: null,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  webrtcAudioState: function (state) {
    return state.webrtcAudioState
  },
  webrtcAudioContext: function (state) {
    return state.webrtcAudioContext
  },
  webrtcAudioSenderState: function (state) {
    return state.webrtcAudioSenderState
  },
  webrtcAudioSenderContext: function (state) {
    return state.webrtcAudioSenderContext
  },
  webrtcVideoLog: function (state) {
    return state.webrtcVideoLog
  },
  showWebrtcVideoDetails: function (state) {
    return state.showWebrtcVideoDetails
  },
  webrtcVideoDetailsHtml: function (state) {
    return state.webrtcVideoDetailsHtml
  },
  currentPageDeviceId: function (state) {
    return state.currentPageDeviceId
  }
}

// actions
const actions = {
  getWebrtcIceServers: function ({commit}, {siteId, provider}) {
    commit(CREATE_WEBRTC_ICE_SERVERS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getWebrtcIceServers({siteId, provider}).then(
        res => {
          let data = res.body
          commit(CREATE_WEBRTC_ICE_SERVERS_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CREATE_WEBRTC_ICE_SERVERS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [UPDATE_WEBRTC_AUDIO_STATE]: function (state, data) {
    state.webrtcAudioState = data
  },
  [UPDATE_WEBRTC_AUDIO_SENDER_STATE]: function (state, data) {
    state.webrtcAudioSenderState = data
  },
  [STORE_WEBRTC_AUDIO_CONTEXT]: function (state, data) {
    state.webrtcAudioContext = data
  },
  [STORE_WEBRTC_AUDIO_SENDER_CONTEXT]: function (state, data) {
    state.webrtcAudioSenderContext = data
  },
  [STORE_WEBRTC_INFO_BRIDGES]: function (state, data) {
    state.webrtcBridges = data
  },
  [SET_WEBRTC_VIDEO_LOG]: function (state, log) {
    // 1000개 이상이면 삭제
    if (state.webrtcVideoLog.length > 1000) state.webrtcVideoLog.shift()
    
    state.webrtcVideoLog.push(log)
  },
  [REMOVE_WEBRTC_VIDEO_LOGS]: function (state) {
    state.webrtcVideoLog = []
  },
  [CREATE_WEBRTC_ICE_SERVERS_REQUEST]: function (state) {
    state.status.getWebrtcIceServers = 'requested'
  },
  [CREATE_WEBRTC_ICE_SERVERS_SUCCESS]: function (state, {data}) {
    state.status.getWebrtcIceServers = 'successful'
  },
  [CREATE_WEBRTC_ICE_SERVERS_FAILURE]: function (state) {
    state.status.getWebrtcIceServers = 'failed'
  },
  [SET_WEBRTC_VIDEO_DETAILS]: function (state, isShow) {
    state.showWebrtcVideoDetails = isShow
  },
  [SET_WEBRTC_VIDEO_DETAILS_TO_HTML]: function (state, html) {
    state.webrtcVideoDetailsHtml = html
  },
  [SET_CURRENT_PAGE_DEVICE_ID]: function (state, deviceId) {
    state.currentPageDeviceId = deviceId
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}